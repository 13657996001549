import { OutlinedTextFieldProps, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FastField, FieldProps } from "formik";
import * as React from "react";

interface FormikTextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  name: string;
  rounded?: boolean;
}

const useStyles = makeStyles({
  rounded: { borderRadius: "16px" },
});

export const FormikTextField: React.FC<FormikTextFieldProps> = (props) => {
  const classes = useStyles();
  const { rounded, ...allProps } = props;
  return (
    <FastField name={allProps.name}>
      {({ field, meta }: FieldProps<string>) => (
        <TextField
          data-testid={allProps.name}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error}
          variant="outlined"
          fullWidth
          {...field}
          {...allProps}
          InputProps={{
            classes: { root: rounded ? classes.rounded : "" },
          }}
        />
      )}
    </FastField>
  );
};
