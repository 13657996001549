import { Box, Breakpoint, Card, CardContent, Container } from "@mui/material";
import Image from "next/image";
import type { FC } from "react";

export const AuthFormWrapper: FC<{ title: string; size?: Breakpoint }> = ({
  title,
  children,
  size = "sm",
}) => (
  <>
    <title>{title} | Flourishing Life</title>

    <Box
      sx={{
        backgroundColor: "primary.theme",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth={size} sx={{ py: "80px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Image
            alt="FL Logo"
            src="/logos/flourish-logo-full.svg"
            width={170}
            height={170}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 8,
          }}
        />
        <Card>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 4,
            }}
          >
            {children}
          </CardContent>
        </Card>
      </Container>
    </Box>
  </>
);
