/**
 * Global routes table
 */
export const ROUTES = {
  // Dashboard
  DASHBOARD_ROOT: "/dashboard",
  DASHBOARD_ADMIN: "/dashboard",
  DASHBOARD_USER: "/dashboard/user",
  TEST: "/dashboard/test",
  FLOURISHING_PROFILE: "/dashboard/flourishing-profile",

  // Auth
  AUTH_LOGIN: "/authentication/login",
  AUTH_FORGOT_PASSWORD: "/authentication/forgot-password",
  AUTH_CHANGE_PASSWORD: "/authentication/change-password",
  AUTH_RESET_PASSWORD: "/authentication/forgot-password-reset",
  AUTH_VERIFY_MFA: "/authentication/verify-mfa",
  AUTH_REGISTER: "/join-code/register",
  AUTH_JOIN_CODE: "/join-code",
  AUTH_UNIQUE_ID: "/join-code/unique-id",

  // Test management
  TESTS_ASSIGN: "/tests/assign",
  TESTS_EDIT: "/tests/edit",
  TESTS_LIST: "/tests",
  TESTS_CREATE: "/tests/create",
  TESTS_STATUS: "/tests/status",
  TESTS_NO_TEST: "/tests/no-test",
  TESTS_COMPLETED_TEST: "/tests/completed-test",
  CUSTOM_REPORTS: "/tests/custom-reports",
  CUSTOM_REPORTS_VIEW_SINGLE: "/tests/custom-reports/view",
  CUSTOM_REPORTS_CREATE: "/tests/custom-reports/create",

  // Client management
  CLIENT_ROOT: "/clients",
  CLIENT_JOIN_CODE: "/clients/join-code",
  CLIENT_MANAGER: "/clients",
  CLIENT_CREATE: "/clients/create",
  CLIENT_VIEW_SINGLE: "/clients/view",

  // Reports
  REPORTS_ROOT: "/reports",
  REPORTS_CORE_COMPETENCY: "/reports/core-competency",
  REPORTS_NINE_HABITS: "/reports/nine-habits",
  REPORTS_CUSTOM_QUESTIONS: "/reports/custom-questions-report",
  REPORTS_FLOURISHING_MINDSETS: "/reports/flourishing-mindsets",
  REPORTS_PERFORMANCE_CAPACITY: "/reports/performance-capacity",
  REPORTS_RESILIENCE_CAPACITY: "/reports/resiliency-capacity",
  REPORTS_SEVEN_WELL_BEING_FACTORS: "/reports/seven-well-being-factors",
  REPORTS_FLOURISHING_SUMMARY: "/reports/flourishing-summary",
  REPORTS_CLIENT_LIST: "/reports/client-list",
  REPORTS_CUSTOM_BASE: "/reports/custom-report-base",

  // Trend views
  TRENDS_RESILIENCE_CAPACITY: "/trends/resiliency-capacity",
  TRENDS_PERFORMANCE_CAPACITY: "/trends/performance-capacity",
  TRENDS_SEVEN_WELL_BEING_FACTORS: "/trends/seven-well-being-factors",
  TRENDS_CORE_COMPETENCY: "/trends/core-competency",
  TRENDS_FLOURISHING_MINDSET: "/trends/flourishing-mindset",
  TRENDS_NINE_HABITS: "/trends/nine-habits",
  TRENDS_FLOURISHING_SUMMARY: "/trends/flourishing-summary",
  TRENDS_CUSTOM_BASE: "/trends/custom-trend-base",

  // FL Admin
  LICENSE_MANAGER: "/fl-admin/license-manager",
  INSTANCE_EDIT: "/fl-admin/organization-manager/edit",
  INSTANCE_CREATE: "/fl-admin/organization-manager/create",
  INSTANCE_LIST: "/fl-admin/organization-manager",

  // Misc
  UNAUTHORIZED: "/unauthorized",
  PROFILE: "/profile",
};
