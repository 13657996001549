import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const LoginHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mb: 2 }}>
      <Typography color="textPrimary" gutterBottom variant="h4">
        {t("login.title.title")}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        {t("login.title.label")}
      </Typography>
    </Box>
  );
};
