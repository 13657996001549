import { Box, Button, FormHelperText, Link, Divider } from "@mui/material";
import { AxiosError } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { SlugLink } from "components/SlugLink";
import { FormikTextField } from "components/form/FormikTextField";

import { ROUTES } from "constants/routes";
import { useAuth } from "hooks/useAuth";
import { useSlugRouter } from "hooks/useSlugRouter";

import { ApiError } from "types/ApiErrors";

export interface LoginFormState {
  username: string;
  password: string;
  slug: string;
  submit?: string;
}

const extendRoute = (route: string, slug: string, isGeneral: boolean) => {
  if (isGeneral) {
    return `/${slug}${route}`;
  }
  return route;
};

export const LoginForm: React.FC<{ isGeneral: boolean }> = (
  { isGeneral = false },
  props
) => {
  const router = useSlugRouter();
  const { login } = useAuth();
  const { t } = useTranslation();

  const loginFormSchema: Yup.SchemaOf<LoginFormState> = Yup.object({
    username: Yup.string().required(t("login.email.error")),
    password: Yup.string().required(t("login.password.error")),
    slug: Yup.string().required(t("login.instance.error")),
    submit: Yup.string(),
  });

  const handleSubmitLogin = async (
    values: LoginFormState,
    { setErrors, setSubmitting }: FormikHelpers<LoginFormState>
  ) => {
    try {
      const status = await login({
        username: values.username,
        password: values.password,
        slug: values.slug,
      });

      setSubmitting(false);
      switch (status) {
        case "FORCE_CHANGE_PASSWORD":
          await router.push({
            pathname: extendRoute(
              ROUTES.AUTH_CHANGE_PASSWORD,
              values.slug,
              isGeneral
            ),
            query: { username: values.username },
          });
          break;
        case "NEED_MFA":
          await router.push({
            pathname: extendRoute(
              ROUTES.AUTH_VERIFY_MFA,
              values.slug,
              isGeneral
            ),
            query: { username: values.username },
          });
          break;
        case "CONFIRMED":
          await router.push(extendRoute("", values.slug, isGeneral));
          break;
        default:
          await router.push(ROUTES.AUTH_LOGIN);
      }
    } catch (err) {
      const error = err as AxiosError<ApiError>;
      setErrors({ submit: error.response?.data?.message ?? error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        slug: router.slug,
      }}
      validationSchema={loginFormSchema}
      onSubmit={handleSubmitLogin}
      enableReinitialize
    >
      {({ errors, isSubmitting, values }) => (
        <Form {...props}>
          {isGeneral && (
            <FormikTextField
              name="slug"
              margin="normal"
              label={t("login.instance.label")}
              type="text"
            />
          )}

          <FormikTextField
            name="username"
            margin="normal"
            autoComplete="username"
            label={t("login.email.useremail")}
            type="username"
          />

          <FormikTextField
            name="password"
            margin="normal"
            autoComplete="password"
            label={t("login.password.label")}
            type="password"
          />

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <Button
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t("login.loginbutton")}
            </Button>
          </Box>

          <Divider sx={{ my: 2 }} />
          <span>
            {values.slug && (
              <SlugLink href={ROUTES.AUTH_JOIN_CODE} passHref>
                <Link color="textSecondary" variant="body2">
                  Have a join code?
                </Link>
              </SlugLink>
            )}
            &nbsp; &nbsp;
            {values.slug && (
              <SlugLink
                href={extendRoute(
                  ROUTES.AUTH_FORGOT_PASSWORD,
                  values.slug,
                  isGeneral
                )}
                passHref
              >
                <Link color="textSecondary" variant="body2">
                  {t("login.forgot_password")}
                </Link>
              </SlugLink>
            )}
          </span>
        </Form>
      )}
    </Formik>
  );
};
