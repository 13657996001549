import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

export const SlugLink: React.FC<LinkProps> = (props) => {
  const router = useRouter();
  const { slug } = router.query;

  let { href, as: alias } = props;

  if (typeof slug === "string") {
    if (typeof href === "string") {
      href = `/${slug}${href}`;
    }
    if (typeof alias === "string") {
      alias = `/${slug}${alias}`;
    }
  }

  return <Link {...props} href={href} as={alias} />;
};
